.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
}

.popup-content h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.popup-content ul {
    list-style-type: none;
    padding: 10px;
    border: 1px solid #ccc; /* Add border to create outline */
    border-radius: 4px; /* Optional: Add border-radius for rounded corners */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); /* Optional: Add box-shadow for depth */
}

.popup-content li {
    margin-bottom: 10px;
}

.popup-content label {
    display: flex;
    align-items: center;
}

.popup-content input[type="checkbox"] {
    margin-right: 10px;
}

.btn-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.save-btn, .close-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.save-btn {
    /* background-color: #4CAF50; */
    color: white;
}

.close-btn {
    background-color: #f44336;
    color: white;
}

.save-btn:hover, .close-btn:hover {
    opacity: 0.8;
}
