.header {
    background-color: #E2F5EC;
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between; /* Changed from space-around to space-between */
    align-items: center;
    padding: 0 55px; /* Added padding for better spacing */
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.header-left {
    display: flex;
    gap: 10px;
    align-items: center;
}

.header-right {
    display: flex;
    gap: 10px;
    align-items: center;
}

.header-logo {
    height: 50px;
}

.header-title {
    margin-right: auto; /* Pushes the title to the left as much as possible */
}

.header-nav {
    display: flex;
}

.header-nav-item {
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
    margin-right: 20px;
    font-weight: 500;
}

.header-nav-item:hover {
    color: #067C4E;
}

.user-dropdown {
    position: relative;
}
  
.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 150px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1000;
}
  
.dropdown-menu button {
    display: block;
    width: 100%;
    padding: 8px 12px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
}
  
.dropdown-menu button:hover {
    background-color: #f0f0f0;
}