body {
  background-color: #f5f5f5; /* This corresponds to gray-100 in Tailwind CSS */
}

.discom-list::-webkit-scrollbar {
    display: none;
}

.state-list::-webkit-scrollbar {
    display: none;
}

.powerPlant .react-datepicker-wrapper{
    width: 100%!important;
}

.react-datepicker__day--outside-month{
    color: gray !important;
}

/* Loader.css */
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-container {
  position: relative;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
}
/* Custom scrollbar for WebKit browsers */
::-webkit-scrollbar {
    width: 12px; /* Width of the entire scrollbar */
  }
  
  /* Background of the scrollbar track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
  
  /* The scrollbar thumb (draggable part) */
  ::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px;       /* Rounded corners */
    border: 3px solid #f1f1f1;  /* Creates padding around thumb */
  }
  
  /* Hover state for the scrollbar thumb */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
  }
  
  /* Optional: customize the scrollbar corner (when both vertical and horizontal scrollbars are visible) */
  ::-webkit-scrollbar-corner {
    background: #f1f1f1;
  }
  