/* Background Colors */
.bg-white {
    background-color: #fff;
}

.bg-white-600 {
    background-color: #f3f4f6; 
}

/* Shadows */
.shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

/* Text Properties */
.text-gray {
    color: #777;
}

.text-gray-700 {
    color: #333;
}

.text-lg {
    font-size: 1.125rem;
    font-weight: 600;
}

.font-semibold {
    font-weight: 600;
}

/* Form Styles */
.form-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    outline: none;
}

/* Button Styles */
.button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0056b3;
}

/* Error Message Styles */
.error-message {
    color: red;
    font-size: 0.875rem;
    position: absolute;
    top: 100%;
    left: 0;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.tabs {
    display: flex;
}
  
.tabs button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
}
  
.tabs button.active {
    background-color: #ddd; /* Change background color of active tab */
}
  
.image-grid {
    display: flex;
    flex-wrap: wrap;
    max-height: 300px; /* Set a maximum height for the image grid */
    overflow-y: auto; /* Add a vertical scroll bar if the content overflows */
}
  
.image-item {
    width: 190px; /* Adjust width to fit 4 thumbnails per row with margins */
    height: 100px;
    margin: 5px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}
  
.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.upload-button {
    background-color: #007bff;
    color: #fff; 
    border-radius: 5px;
    padding: 3px 8px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px; 
}
  
.upload-button:hover {
    background-color: #0056b3; 
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%; 
    height: 80%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 9999; /* Ensure the popup is above other content */
}
  
.popup .tabs {
    display: flex;
}
  
.popup .tabs button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
}
  
.popup .tabs button.active {
    background-color: #ddd; /* Change background color of active tab */
}
  
.popup .upload-button {
    background-color: #007bff; /* Blue color for upload button */
    color: #fff; /* White text color */
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px; /* Add some top margin to separate from other content */
}
  
.popup .upload-button:hover {
    background-color: #0056b3; /* Darker blue color on hover */
}

.add-image-button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
  
.close-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.image-item.selected {
    border: 3px solid blue; /* You can adjust the border color and width as needed */
  }